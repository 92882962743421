var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AError", { attrs: { api: this.api } }),
      _c(
        "v-btn",
        {
          staticClass: "my-3",
          attrs: { color: "primary" },
          on: { click: _vm.closeDetail },
        },
        [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
        1
      ),
      _c(
        "v-card",
        { attrs: { color: "secondary" } },
        [
          _c("div", { staticClass: "text-h5 pa-5 white--text" }, [
            _vm._v(" Payment status update "),
          ]),
          this.company != null
            ? _c("p", { staticClass: "px-5 white--text" }, [
                _vm._v(_vm._s(_vm.company.name)),
              ])
            : _vm._e(),
          _c(
            "v-list",
            { attrs: { color: "" } },
            [
              _vm.api.isLoading
                ? _c("v-skeleton-loader", {
                    ref: "skeleton",
                    attrs: { type: "card-avatar" },
                  })
                : _vm._e(),
              _vm._l(_vm.payment, function (item, index) {
                return _c(
                  "v-list-item",
                  { key: index },
                  [
                    _c(
                      "v-container",
                      [
                        _c("v-card", [
                          _c("div", { staticClass: "pa-4" }, [
                            item.payment_method
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-start text-h6",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.payment_method) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-start mb-2" },
                              [
                                item.status == "Success"
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass: "white--text",
                                        attrs: { color: "green" },
                                      },
                                      [_vm._v(" " + _vm._s(item.status) + " ")]
                                    )
                                  : _vm._e(),
                                item.status == "Fail"
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass: "white--text",
                                        attrs: { color: "red" },
                                      },
                                      [_vm._v(" " + _vm._s(item.status) + " ")]
                                    )
                                  : _vm._e(),
                                item.status == "Pending"
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass: "black--text",
                                        attrs: { color: "yellow" },
                                      },
                                      [_vm._v(" " + _vm._s(item.status) + " ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            item.total_amount != null
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex justify-start mb-2" },
                                  [
                                    _vm._v(
                                      " RM " + _vm._s(item.total_amount) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            item.created_at != null
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex justify-start mb-2" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.created_at.split("T")[0]) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            item.attachment_url != null
                              ? _c("div", { staticClass: "mb-2" }, [
                                  _vm._v(
                                    " " + _vm._s(item.attachment_type) + " : "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: item.attachment_url,
                                        target: "_blank",
                                        download: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.attachment_name) + " "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _c("hr"),
                            item.fname != null
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex justify-start my-2" },
                                  [
                                    _vm._v(" Report by: "),
                                    _c(
                                      "div",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.fname) +
                                            " " +
                                            _vm._s(item.lname) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                item.remark != null
                                  ? _c("mavon-editor", {
                                      staticStyle: {
                                        "z-index": "0",
                                        "min-height": "100px",
                                        height: "auto",
                                        width: "100%",
                                      },
                                      attrs: {
                                        height: "auto",
                                        width: "auto",
                                        defaultOpen: "preview",
                                        toolbarsFlag: false,
                                        subfield: false,
                                        language: "en",
                                        editable: false,
                                      },
                                      model: {
                                        value: item.remark,
                                        callback: function ($$v) {
                                          _vm.$set(item, "remark", $$v)
                                        },
                                        expression: "item.remark",
                                      },
                                    })
                                  : _c("mavon-editor", {
                                      staticStyle: {
                                        "z-index": "0",
                                        "min-height": "100px",
                                        height: "auto",
                                        width: "100%",
                                      },
                                      attrs: {
                                        height: "auto",
                                        width: "auto",
                                        defaultOpen: "preview",
                                        toolbarsFlag: false,
                                        subfield: false,
                                        language: "en",
                                        editable: false,
                                      },
                                      model: {
                                        value: _vm.isRemarkNull,
                                        callback: function ($$v) {
                                          _vm.isRemarkNull = $$v
                                        },
                                        expression: "isRemarkNull",
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }